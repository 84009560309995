export default function auth({
  next,
  router,
  store,
  to
}) {
  // if user is loading a announcement URL, store in state so we can use it later:
  if (to.params.view) {
    console.log(to.params);
    console.log("gonna view announcements");
    store.commit("updateRedirectAnnouncements");
  }

  // if user is loading a notification URL, store the date param in state so we can use it later:
  if (to.params.date) {
    const date = to.params.date;
    store.commit("updateNotificationDate", {
      notificationDate: date
    });
  }

  // if user is loading a snapshot URL, store the snapid param in state so we can use it later:
  if (to.params.snapid) {
    const snapid = to.params.snapid;
    store.commit("updateSnapshotId", {
      snapshotid: snapid
    });
  }

  // if user is not logged in or not on password reset view, redirect to login view
  if (store.state.user.role === "" && router.name !== "Reset") {
    return router.push({
      name: "Login"
    });
  }

  return next();
}