import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#72848B',
                secondary: '#b0bdc8',
                // accent: colors.shades.black,
                // error: colors.red.accent3,
            },
            dark: {
                primary: '#000000'
            }
        }
    }
});
