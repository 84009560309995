<template>
  <v-app :style="compBackgroundColor">
    <SideNav v-if="$store.state.user.role" />

    <transition name="fade">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
// import rawData from "@/assets/SAMPLE.json";
// import rawData from "@/assets/SAMPLE2.json";
// import rawData from "@/assets/SAMPLE3.json";
import SideNav from "./components/SideNav";

export default {
  name: "App",

  components: {
    SideNav
  },

  data: () => ({
    rawData: [
      {
        author: "",
        category: "",
        date: "",
        id: "",
        isFavorite: false,
        title: ""
      }
    ],
    authors: ["Alex", "Bobby", "Carol", "Danny", "Fred"]
  }),
  computed: {
    compBackgroundColor() {
      if (this.$vuetify.theme.dark) {
        return "background-color: #000000";
      }
      return "background-color: #72848B";
    }
  },
  methods: {
    getAuthor(authorID) {
      let authors = this.$store.state.users;
      authors = authors.filter(a => a.id === authorID);
      return authors[0].firstname;
    }
  },
  mounted: function() {}
};
</script>
<style lang="scss">
// typography
@font-face {
  font-family: "GothamExLight";
  src: url("assets/fonts/GothamExLight.otf") format("opentype");
}
@font-face {
  font-family: "GothamLight";
  src: url("assets/fonts/GothamLight.otf") format("opentype");
}
@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/GothamMedium.otf") format("opentype");
}

* {
  font-family: "GothamLight";
}

.v-card__title {
  // font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: "GothamExLight", Arial, sans-serif;
  text-transform: uppercase;
}

a.v-btn span,
button.v-btn span {
  font-family: "Gotham";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.dashboard-cms,
.v-dialog--active {
  h1 {
    font-family: "GothamExLight", Arial, sans-serif;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
  }
  h2 {
    font-family: "GothamExLight", Arial, sans-serif;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }
  img {
    max-width: 100%;
  }
  p.ql-align-center {
    text-align: center;
  }
  p.ql-align-right {
    text-align: right;
  }
  li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
}

.ql-editor .ql-video {
  display: inline-block !important;
  margin-right: 10px;
}

.theme--dark.v-card a {
  color: #ffffff;
}
</style>
