import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import auth from "../middleware/auth";
import log from "../middleware/log";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Login",
    component: () =>
      import( /* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/reset/:username?/:hash?",
    name: "Reset",
    component: () =>
      import( /* webpackChunkName: "login" */ "../views/Reset.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "profile" */ "../views/Profile.vue")
  },
  {
    path: "/cms",
    name: "CMS",
    meta: {
      middleware: [auth, log]
    },
    component: () => import( /* webpackChunkName: "cms" */ "../views/CMS.vue")
  },
  // {
  //   path: "/snapshots",
  //   name: "Snapshots",
  //   meta: {
  //     middleware: [auth, log]
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "snapshots" */ "../views/Snapshots.vue")
  // },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import( /* webpackChunkName: "logout" */ "../views/Logout.vue")
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "users" */ "../views/Users.vue")
  },
  {
    path: "/activity",
    name: "Activity",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "activity" */ "../views/Activity.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/documents",
    name: "Documents",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "documents" */ "../views/Documents.vue")
  },
  {
    path: "/bulletins/:view?",
    name: "Bulletins",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "bulletins" */ "../views/Bulletins.vue")
  },
  {
    path: "/notifications/:date?",
    name: "Notifications",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */
        "../views/Notifications.vue"
      )
  },
  {
    path: "/snapshots/:snapid?",
    name: "Snapshots",
    meta: {
      middleware: [auth, log]
    },
    component: () =>
      import( /* webpackChunkName: "snapshots" */ "../views/Snapshots.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ?
      to.meta.middleware :
      [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
      store
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({
      ...context,
      next: nextMiddleware
    });
  }

  return next();
});

export default router;