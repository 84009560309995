<template>
  <v-navigation-drawer id="sidenav" permanent expand-on-hover elevation="2">
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="@/assets/images/LiveWellnessIcon.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-title
          ><v-img
            src="@/assets/images/LiveWellnessText.png"
            max-height="30"
            max-width="140"
          ></v-img
        ></v-list-item-title>
      </v-list-item>

      <!-- <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    Sandra Adams
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >sandra_a88@gmail.com</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item> -->
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/documents">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Files</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/bulletins">
        <v-list-item-icon>
          <v-badge :content="bulletins" :value="bulletins" color="red" overlap>
            <v-icon>mdi-bugle</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title>Announcements</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/profile">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Profile</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/notifications">
        <v-list-item-icon>
          <v-badge
            :content="notifications"
            :value="notifications"
            color="red"
            overlap
          >
            <v-icon> mdi-bell </v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title>Notifications</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="role === 'admin'" link to="users">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>User Management</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="role === 'admin' || role === 'corporate'"
        link
        to="/activity"
      >
        <v-list-item-icon>
          <v-icon>mdi-fingerprint</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Activity Log</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="role === 'admin' || role === 'corporate'"
        link
        to="/cms"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>CMS</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideNav",

  data: () => ({
    // notifications: 2
  }),
  computed: {
    role() {
      return this.$store.state.user.role;
    },
    notifications() {
      return this.$store.state.notifications;
    },
    bulletins() {
      // return this.$store.state.bulletins.length;
      return 0;
    }
  }
};
</script>

<style lang="scss">
#sidenav {
  z-index: 100;
  position: fixed;
  top: 0px;
}
</style>
