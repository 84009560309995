import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toasted from "vue-toasted";

Vue.config.productionTip = false;
Vue.use(Toasted, {
  iconPack: "mdi" // set your iconPack, defaults to material. material|fontawesome|custom-class
});

new Vue({
  computed: {
    urls() {
      let obj = {
        api: process.env.VUE_APP_API
      };
      return obj;
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
