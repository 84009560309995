import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      username: "",
      role: "",
      firstname: "",
      lastname: "",
      token: "",
      phone: "",
      notification: "",
      unread: [],
      darkMode: false
    },
    users: [],
    documents: [],
    notifications: 0,
    bulletins: [],
    notificationDate: "",
    snapshotid: "",
    redirectAnnouncements: false,
    dashboard: "",
    cms: []
  },
  mutations: {
    login(state, payload) {
      state.user.username = payload.username;
      state.user.role = payload.role;
      state.user.firstname = payload.firstname;
      state.user.lastname = payload.lastname;
      state.user.token = payload.token;
      state.user.phone = payload.phone;
      state.user.notification = payload.notification;
      state.user.unread = payload.unread;
      state.user.darkMode = payload.darkMode;
    },
    updateProfile(state, payload) {
      state.user.firstname = payload.firstname;
      state.user.lastname = payload.lastname;
      state.user.phone = payload.phone;
      state.user.notification = payload.notification;
      state.user.darkMode = payload.darkMode;
    },
    saveDocuments(state, payload) {
      state.documents = payload.documents;
    },
    updateDocument(state, payload) {
      let data = state.documents;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.document._id) {
          data[i].isFavorite = payload.document.isFavorite;
        }
      }
    },
    deleteDocument(state, payload) {
      // console.log(state.documents.length);
      let data = state.documents;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.document._id) {
          state.documents.splice(i, 1);
        }
      }
    },
    addActivity(state, payload) {
      state.activities.push(payload.activity);
    },
    updateNotification(state, payload) {
      state.notifications = payload.notifications;
    },
    updateNotificationDate(state, payload) {
      state.notificationDate = payload.notificationDate;
    },
    updateSnapshotId(state, payload) {
      state.snapshotid = payload.snapshotid;
    },
    updateNotificationUnread(state, payload) {
      state.user.unread = payload.unread;
    },
    updateRedirectAnnouncements(state) {
      state.redirectAnnouncements = true;
    },
    updateDashboard(state, payload) {
      state.cms = payload.cms;
    },
    updateBulletins(state, payload) {
      state.bulletins = payload.bulletins;
    },
    addSingleCMS(state, payload) {
      state.cms.push(payload.cms);
    },
    updateSingleCMS(state, payload) {
      let data = state.cms;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.cms._id) {
          data[i] = payload.cms;
        }
      }
    },
    deleteSingleCMS(state, payload) {
      let data = state.cms;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.cms._id) {
          state.cms.splice(i, 1);
        }
      }
    },
    addBulletin(state, payload) {
      state.bulletins.push(payload.bulletin);
    },
    updateBulletin(state, payload) {
      let data = state.bulletins;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.bulletin._id) {
          // data[i] = payload.bulletin
          state.bulletins.splice(i, 1);
          state.bulletins.push(payload.bulletin);
        }
      }
    },
    deleteBulletin(state, payload) {
      let data = state.bulletins;
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id === payload.bulletin._id) {
          state.bulletins.splice(i, 1);
        }
      }
    }
  },
  actions: {},
  modules: {}
});